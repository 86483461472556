import Vue from 'vue';
import types from '../mutation-types';
import automationRegistryAPI from '../../api/wgpt/automation_registry';
import { throwErrorMessage } from '../utils/api';

export const state = {
  triggerRecords: {},
  actionRecords: [],
  dynamicOptionsRecords: [],
  customObjectRecords: [],
  logicalOperatorRecords: [],
  comparisonOperatorRecords: [],
  uiFlags: {
    isFetchingTriggers: false,
    isFetchingActions: false,
    isFetchingFields: false,
    isFetchingCustomObjects: false,
    isFetchingLogicalOperators: false,
    isFetchingComparisonOperators: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
  },
};

export const getters = {
  getTriggers($state) {
    return $state.triggerRecords;
  },
  getTrigger: $state => triggerName => {
    return $state.triggerRecords[triggerName];
  },
  getActions($state) {
    return $state.actionRecords;
  },
  getAction: $state => actionName => {
    return $state.actionRecords.find(({ action }) => action === actionName);
  },
  getLatestVersion: $state => actionName => {
    const parts = actionName.split('_');
    const versionPart = parts[parts.length - 1];
    const hasVersion = /^v\d+$/.test(versionPart);
    const baseActionName = hasVersion
      ? parts.slice(0, -1).join('_')
      : actionName;

    const actionRecords = $state.actionRecords;
    const actionRegex = new RegExp(`^${baseActionName}(_v\\d+)?$`);
    const actions = actionRecords.filter(({ action }) =>
      actionRegex.test(action)
    );
    const latestVersion = actions.reduce(
      (latest, action) => {
        if (action.version > latest.version) {
          return action;
        }
        return latest;
      },
      { version: 0 }
    );
    return latestVersion;
  },
  getDynamicOptions: $state => fieldName => {
    return $state.dynamicOptionsRecords.find(({ name }) => name === fieldName);
  },
  getCustomObjects: $state => objectName => {
    return $state.customObjectRecords.find(({ name }) => name === objectName);
  },
  getLogicalOperators($state) {
    return $state.logicalOperatorRecords;
  },
  getComparisonOperators($state) {
    return $state.comparisonOperatorRecords;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  getTriggers: async ({ commit }) => {
    commit(types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_UI_FLAG, {
      isFetchingTriggers: true,
    });
    try {
      const { data } = await automationRegistryAPI.getTriggers();
      commit(types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_TRIGGERS, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_UI_FLAG, {
        isFetchingTriggers: false,
      });
    }
  },
  getActions: async ({ commit }) => {
    commit(types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_UI_FLAG, {
      isFetchingActions: true,
    });
    try {
      const { data } = await automationRegistryAPI.getActions();
      commit(types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_ACTIONS, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_UI_FLAG, {
        isFetchingActions: false,
      });
    }
  },
  getDynamicOptions: async ({ commit }, args) => {
    const fieldName = args.fieldName ?? args;
    const params = args.params ?? {};
    commit(types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_UI_FLAG, {
      isFetchingFields: true,
    });
    try {
      const { data } = await automationRegistryAPI.getDynamicOptions(
        fieldName,
        params
      );
      commit(types.ADD_WGPT_BOARDS_AUTOMATION_REGISTRY_FIELDS, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_UI_FLAG, {
        isFetchingFields: false,
      });
    }
  },
  getCustomObjects: async ({ commit }, objectName) => {
    commit(types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_UI_FLAG, {
      isFetchingCustomObjects: true,
    });
    try {
      const { data } = await automationRegistryAPI.getCustomObjects(objectName);
      commit(types.ADD_WGPT_BOARDS_AUTOMATION_REGISTRY_CUSTOM_OBJECTS, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_UI_FLAG, {
        isFetchingCustomObjects: false,
      });
    }
  },
  getActionsForTrigger: async ({ commit }, triggerId) => {
    commit(types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_UI_FLAG, {
      isFetchingActions: true,
    });
    try {
      const { data } =
        await automationRegistryAPI.getActionsForTrigger(triggerId);
      commit(types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_ACTIONS, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_UI_FLAG, {
        isFetchingActions: false,
      });
    }
  },
  getLogicalOperators: async ({ commit }) => {
    commit(types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_UI_FLAG, {
      isFetchingLogicalOperators: true,
    });
    try {
      const { data } = await automationRegistryAPI.getLogicalOperators();
      commit(types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_LOGICAL_OPERATORS, data);
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_UI_FLAG, {
        isFetchingActions: false,
      });
    }
  },
  getComparisonOperators: async ({ commit }) => {
    commit(types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_UI_FLAG, {
      isFetchingComparisonOperators: true,
    });
    try {
      const { data } = await automationRegistryAPI.getComparisonOperators();
      commit(
        types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_COMPARISON_OPERATORS,
        data
      );
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_UI_FLAG, {
        isFetchingActions: false,
      });
    }
  },
};

export const mutations = {
  [types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_TRIGGERS]($state, data) {
    $state.triggerRecords = data;
  },
  [types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_ACTIONS]($state, data) {
    $state.actionRecords = data;
  },
  [types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_LOGICAL_OPERATORS]($state, data) {
    $state.logicalOperatorRecords = data;
  },
  [types.SET_WGPT_BOARDS_AUTOMATION_REGISTRY_COMPARISON_OPERATORS](
    $state,
    data
  ) {
    $state.comparisonOperatorRecords = data;
  },
  [types.ADD_WGPT_BOARDS_AUTOMATION_REGISTRY_FIELDS]($state, data) {
    const index = $state.dynamicOptionsRecords.findIndex(
      ({ name }) => name === data.name
    );
    if (index === -1) {
      $state.dynamicOptionsRecords.unshift(data);
    } else {
      const dataBefore = $state.dynamicOptionsRecords[index];
      Vue.set($state.dynamicOptionsRecords, index, { ...dataBefore, ...data });
    }
  },
  [types.ADD_WGPT_BOARDS_AUTOMATION_REGISTRY_CUSTOM_OBJECTS]($state, data) {
    const index = $state.customObjectRecords.findIndex(
      ({ name }) => name === data.name
    );
    if (index === -1) {
      $state.customObjectRecords.unshift(data);
    } else {
      const dataBefore = $state.customObjectRecords[index];
      Vue.set($state.customObjectRecords, index, { ...dataBefore, ...data });
    }
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
