import { render, staticRenderFns } from "./WgptAutomationRow.vue?vue&type=template&id=3c97d757&"
import script from "./WgptAutomationRow.vue?vue&type=script&setup=true&lang=js&"
export * from "./WgptAutomationRow.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports