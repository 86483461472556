<script>
import { useAlert } from 'dashboard/composables';
import { mapGetters } from 'vuex';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      name: '',
      description: '',
    };
  },
  validations() {
    return {
      name: {
        required,
      },
      description: {},
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'wgptAutomations/getUIFlags',
    }),
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    async onSubmit() {
      try {
        if (this.v$.$invalid) {
          this.v$.$touch();
          return;
        }

        const automation = {
          name: this.name,
          description: this.description,
          trigger: {},
          steps: {
            v: [],
          },
        };
        const automationRef = await this.$store.dispatch(
          'wgptAutomations/create',
          automation
        );

        useAlert(this.$t('WGPT_AUTOMATIONS.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
        this.$router.push({
          name: 'wgpt_automations_edit',
          params: {
            automationId: automationRef.id,
          },
        });
      } catch (error) {
        const errorMessage =
          error.message || this.$t('WGPT_AUTOMATIONS.ADD.API.ERROR_MESSAGE');
        useAlert(errorMessage);
      }
    },
  },
};
</script>

<template>
  <div class="h-auto overflow-auto flex flex-col">
    <woot-modal-header
      :header-title="$t('WGPT_AUTOMATIONS.ADD.TITLE')"
      :header-content="$t('WGPT_AUTOMATIONS.ADD.DESC')"
    />
    <form class="w-full" @submit.prevent="onSubmit">
      <div>
        <label :class="{ error: v$.name.$error }">
          {{ $t('WGPT_AUTOMATIONS.ADD.FORM.NAME.LABEL') }}
          <woot-input v-model.trim="name" @input="v$.name.$touch" />
          <span v-if="v$.name.$error" class="message">
            {{ $t('WGPT_AUTOMATIONS.ADD.FORM.NAME.ERROR') }}
          </span>
        </label>
      </div>

      <div>
        <label :class="{ error: v$.description.$error }">
          {{ $t('WGPT_AUTOMATIONS.ADD.FORM.DESCRIPTION.LABEL') }}
          <textarea
            v-model.trim="description"
            class="disabled:opacity-50 disabled:bg-white disabled:dark:bg-slate-900"
            rows="3"
            @input="v$.description.$touch"
          />
        </label>
      </div>

      <div class="flex justify-end items-center py-2 px-0 gap-2 w-full">
        <woot-button
          :is-loading="uiFlags.isCreating"
          data-testid="board-submit"
        >
          {{ $t('WGPT_AUTOMATIONS.ADD.FORM.SAVE') }}
        </woot-button>
        <woot-button class="button clear" @click.prevent="onClose">
          {{ $t('WGPT_AUTOMATIONS.ADD.FORM.CANCEL') }}
        </woot-button>
      </div>
    </form>
  </div>
</template>
