<script setup>
import { messageStamp } from 'shared/helpers/timeHelper';

defineProps({
  automation: {
    type: Object,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['edit', 'clone', 'delete', 'toggle']);

const readableTime = date => {
  if (!date) return '';
  return messageStamp(new Date(date).getTime() / 1000, 'LLL d, h:mm a');
};
</script>

<template>
  <tr>
    <td class="py-4 ltr:pr-4 rtl:pl-4 truncate">{{ automation.name }}</td>
    <td class="py-4 ltr:pr-4 rtl:pl-4 truncate">
      {{ automation.description }}
    </td>
    <td class="py-4 ltr:pr-4 rtl:pl-4">
      <woot-switch :value="automation.active" @input="$emit('toggle')" />
    </td>
    <td class="py-4 ltr:pr-4 rtl:pl-4 truncate">
      {{ readableTime(automation.updated_at) }}
    </td>

    <td class="py-4 flex justify-end gap-1">
      <woot-button
        v-tooltip.top="$t('WGPT_AUTOMATIONS.LIST.TOOLTIP.EDIT')"
        variant="smooth"
        size="tiny"
        color-scheme="secondary"
        class-names="grey-btn"
        :is-loading="loading"
        icon="edit"
        @click="$emit('edit')"
      />
      <woot-button
        v-tooltip.top="$t('WGPT_AUTOMATIONS.LIST.TOOLTIP.CLONE')"
        variant="smooth"
        size="tiny"
        color-scheme="primary"
        class-names="grey-btn"
        :is-loading="loading"
        icon="copy"
        @click="$emit('clone')"
      />
      <woot-button
        v-tooltip.top="$t('WGPT_AUTOMATIONS.LIST.TOOLTIP.DELETE')"
        variant="smooth"
        color-scheme="alert"
        size="tiny"
        icon="dismiss-circle"
        class-names="grey-btn"
        :is-loading="loading"
        @click="$emit('delete')"
      />
    </td>
  </tr>
</template>
