<script>
import AddAutomation from './add/Index.vue';
import SettingsLayout from '../SettingsLayout.vue';
import BaseSettingsHeader from '../components/BaseSettingsHeader.vue';
import WgptAutomationRow from './components/WgptAutomationRow.vue';
import { mapGetters } from 'vuex';
import { useAlert } from 'dashboard/composables';

export default {
  components: {
    AddAutomation,
    BaseSettingsHeader,
    SettingsLayout,
    WgptAutomationRow,
  },
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showDeleteConfirmationPopup: false,
      selectedResponse: {},
      toggleModalTitle: this.$t('WGPT_AUTOMATIONS.TOGGLE.ACTIVATION_TITLE'),
      toggleModalDescription: this.$t(
        'WGPT_AUTOMATIONS.TOGGLE.ACTIVATION_DESCRIPTION'
      ),
    };
  },
  computed: {
    ...mapGetters({
      records: ['wgptAutomations/getAutomations'],
      uiFlags: 'wgptAutomations/getUIFlags',
    }),
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('WGPT_AUTOMATIONS.DELETE.CONFIRM.YES')} ${
        this.selectedResponse.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('WGPT_AUTOMATIONS.DELETE.CONFIRM.NO')} ${
        this.selectedResponse.name
      }`;
    },
    deleteMessage() {
      return ` ${this.selectedResponse.name}?`;
    },
  },
  mounted() {
    this.$store.dispatch('wgptAutomations/get');
  },
  methods: {
    editAutomation(automation) {
      this.$router.push({
        name: 'wgpt_automations_edit',
        params: {
          automationId: automation.id,
          headerTitle: automation.name,
        },
      });
    },
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedResponse = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.loading[this.selectedResponse.id] = true;
      this.closeDeletePopup();
      this.deleteAutomation(this.selectedResponse.id);
    },
    async deleteAutomation(id) {
      try {
        await this.$store.dispatch('wgptAutomations/delete', id);
        useAlert(this.$t('WGPT_AUTOMATIONS.DELETE.API.SUCCESS_MESSAGE'));
        this.loading[this.selectedResponse.id] = false;
      } catch (error) {
        useAlert(this.$t('WGPT_AUTOMATIONS.DELETE.API.ERROR_MESSAGE'));
      }
    },
    async cloneAutomation(id) {
      try {
        await this.$store.dispatch('wgptAutomations/clone', id);
        useAlert(this.$t('WGPT_AUTOMATIONS.CLONE.API.SUCCESS_MESSAGE'));
        this.$store.dispatch('wgptAutomations/get');
        this.loading[this.selectedResponse.id] = false;
      } catch (error) {
        useAlert(this.$t('WGPT_AUTOMATIONS.CLONE.API.ERROR_MESSAGE'));
      }
    },
    async toggleAutomation(automation, status) {
      try {
        this.toggleModalTitle = status
          ? this.$t('WGPT_AUTOMATIONS.TOGGLE.DEACTIVATION_TITLE')
          : this.$t('WGPT_AUTOMATIONS.TOGGLE.ACTIVATION_TITLE');
        this.toggleModalDescription = status
          ? this.$t('WGPT_AUTOMATIONS.TOGGLE.DEACTIVATION_DESCRIPTION', {
              automationName: automation.name,
            })
          : this.$t('WGPT_AUTOMATIONS.TOGGLE.ACTIVATION_DESCRIPTION', {
              automationName: automation.name,
            });
        // Check if user confirms to proceed
        const ok = await this.$refs.confirmDialog.showConfirmation();
        if (ok) {
          await await this.$store.dispatch('wgptAutomations/update', {
            id: automation.id,
            active: !status,
          });
          const message = status
            ? this.$t('WGPT_AUTOMATIONS.TOGGLE.DEACTIVATION_SUCCESFUL')
            : this.$t('WGPT_AUTOMATIONS.TOGGLE.ACTIVATION_SUCCESFUL');
          useAlert(message);
        }
      } catch (error) {
        useAlert(this.$t('WGPT_AUTOMATIONS.EDITOR.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>

<template>
  <SettingsLayout
    :no-records-message="$t('WGPT_AUTOMATIONS.LIST.404')"
    :no-records-found="!records.length"
    :is-loading="uiFlags.isFetching"
    :loading-message="$t('WGPT_AUTOMATIONS.LIST.LOADING')"
  >
    <template #header>
      <BaseSettingsHeader
        :title="$t('WGPT_AUTOMATIONS.HEADER')"
        :description="$t('WGPT_AUTOMATIONS.DESCRIPTION')"
        feature-name="wgpt_automations"
      >
        <template #actions>
          <woot-button
            color-scheme="primary"
            class-names="rounded-md"
            @click="openAddPopup"
          >
            <fluent-icon icon="add-circle" />
            <span class="button__content">
              {{ $t('WGPT_AUTOMATIONS.HEADER_BTN_TXT') }}
            </span>
          </woot-button>
        </template>
      </BaseSettingsHeader>
    </template>

    <template #body>
      <table class="min-w-full divide-y divide-slate-75 dark:divide-slate-700">
        <thead>
          <th
            v-for="thHeader in $t('WGPT_AUTOMATIONS.LIST.TABLE_HEADER')"
            :key="thHeader"
            class="py-4 ltr:pr-4 rtl:pl-4 text-left font-semibold text-slate-700 dark:text-slate-300"
          >
            {{ thHeader }}
          </th>
        </thead>
        <tbody
          class="divide-y divide-slate-50 dark:divide-slate-800 text-slate-700 dark:text-slate-300"
        >
          <WgptAutomationRow
            v-for="(automation, index) in records"
            :key="index"
            :automation="automation"
            :loading="loading[automation.id]"
            @edit="editAutomation(automation)"
            @clone="cloneAutomation(automation.id)"
            @delete="openDeletePopup(automation, index)"
            @toggle="toggleAutomation(automation, automation.active)"
          />
        </tbody>
      </table>
    </template>

    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <AddAutomation v-if="showAddPopup" @close="hideAddPopup" />
    </woot-modal>

    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('WGPT_AUTOMATIONS.DELETE.CONFIRM.TITLE')"
      :message="$t('WGPT_AUTOMATIONS.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />

    <woot-confirm-modal
      ref="confirmDialog"
      :title="toggleModalTitle"
      :description="toggleModalDescription"
    />
  </SettingsLayout>
</template>
